$(document).ready(function () {
    var homeHeader = new Swiper('.home-slider', {
        spaceBetween: 15,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
    });

    var swiper = new Swiper(".gallery-swiper", {

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1.3,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3.5,
                spaceBetween: 20,
            },
        },
    });
})
